import React, { useState, useEffect } from "react";
import { BsChevronDown } from "react-icons/bs";
import "./style.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  categoriesDataState,
  selectedCatIdState,
  selectedChatIdState,
} from "../../redux/AppAction";
import { CATEGORY_ID } from "../../utils/constants";
import { routes } from "../../utils/routes";
import { getAllChatHeadsCall, getChatAllListCall } from "../../hooks/chat";
import { ErrorCodes } from "../../utils/enums";
import { isNullOrEmpty } from "../../utils/text-utils";

function Category({ catId, catTitle = "", chatHeadList = [] }) {
  const { userData } = useSelector((state) => state.user);
  const { categoriesData, newChatHead, selectedCategoryId, selectedChatId } = useSelector(
    (state) => state.chat
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let [isOpen, setIsOpen] = useState(false); // State to manage open/close state of dropdown

  const [chat_head_list, set_chat_head_list] = useState([]);

  useEffect(() => {
    debugger;
    if (!isNullOrEmpty(newChatHead) && isOpen) {
      let new_chat_head_list = [...chat_head_list];
      new_chat_head_list.push(newChatHead);
      set_chat_head_list(new_chat_head_list);
      // let categoriesList = [...categoriesData];
      // var categoryData = categoriesList.find((item) => item._id == newChatHead.category._id);
      // var chatHead = categoryData?.chat_heads?.findIndex((item) => item._id == newChatHead._id);
      // if (chatHead === -1) {
      //   categoryData?.chat_heads?.push(newChatHead);
      //   dispatch(categoriesDataState(categoriesList));
      // }
    }
  }, [newChatHead]);

  const toggleDropdown = () => {
    setIsOpen((isOpen = !isOpen));
    if (isOpen) fetchChatHeadChats();
  };

  function fetchChatHeadChats() {
    getAllChatHeadsCall(userData._id, catId)
      .then(({ data }) => {
        if (data.error_code === ErrorCodes.success) {
          set_chat_head_list(data.result);
        } else {
          set_chat_head_list([]);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  }

  function chatHeadClick(chat_head_id) {
    debugger;
    dispatch(selectedChatIdState(chat_head_id));
    dispatch(selectedCatIdState(catId));
    localStorage.setItem(CATEGORY_ID, catId);
    navigate(routes.chat_bot_main_chat, {
      state: { chat_head_id: chat_head_id, catId: catId },
    });
  }

  return (
    <div className="mb-4" key={catId}>
      <button
        type="button"
        className="collapsible flex justify-between items-center"
        onClick={toggleDropdown}
      >
        <p className="pl-2">{catTitle}</p>
        <BsChevronDown
          className={`text-xs 2xl:text-xl mr-4 text-gray-900 font-light ${
            isOpen ? "transform rotate-180" : ""
          }`}
        />
      </button>
      {isOpen && (
        <div className="content">
          <ul>
            {chat_head_list.length === 0 ? (
              <li className="category-item flex text-xs justify-between rounded-xl font-semibold text-gray-600 py-1 hover:bg-gray-200 items-center">
                <div className="px-3">No Chats</div>
              </li>
            ) : (
              chat_head_list.map((item) => (
                <li
                  onClick={() => chatHeadClick(item?._id)}
                  key={item?._id}
                  style={{ cursor: "pointer" }}
                  className={`sm:w-32 md:w-[9rem] lg:w-48 xl:w-56 2xl:w-72 category-item flex text-xs justify-between rounded-xl font-semibold text-gray-600 py-2 ${
                    selectedChatId === item?._id ? "bg-gray-200" : ""
                  } hover:bg-gray-100 items-center`}
                >
                  <div className="px-3 whitespace-nowrap overflow-hidden text-ellipsis">
                    {item?.title}
                  </div>
                </li>
              ))
            )}
          </ul>
        </div>
      )}
    </div>
  );
}

export default Category;
