import React, { useEffect } from 'react';
import Footer from '../home/Footer';

const PrivacyPolicy = () => {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    const scrollToTop = () => {
      window.scrollTo(0, 0);
    };

    // Set a timeout to ensure scroll happens after any content loading
    const timer = setTimeout(scrollToTop, 100); // Adjust delay if necessary

    return () => clearTimeout(timer); // Cleanup timeout on unmount
  }, []);

  return (
    <>
      <div className="max-w-[1150px] mx-auto p-6 bg-white rounded-md m-10">
        <h1 className="text-3xl font-bold text-[#3995EB] mb-4 text-center">Privacy Policy</h1>
        <p className="text-sm text-gray-500 mb-6 text-center"><em>Last Updated: 2024-10-31</em></p>

        <p className="text-gray-700 leading-relaxed mb-4">
          At <strong>Orbi</strong>, we value your trust and are committed to safeguarding your privacy. This Privacy Policy outlines the types of personal information we collect, 
          how we use it, and the measures we take to keep it secure. Our goal is to make sure you feel confident in using Orbi as your comprehensive resource for life insurance 
          and annuity services. Whether you are looking for answers, product guidance, or integration support through Orbix, we prioritize keeping your information protected 
          and ensuring transparency in all our practices.
        </p>

        <h2 className="text-2xl font-semibold text-[#3995EB] mb-2">1. Information We Collect</h2>
        <p className="text-gray-700 leading-relaxed mb-2">
          <strong>Personal Information:</strong> To provide you with tailored insights and a personalized experience, we collect information such as your name, email address, 
          phone number, job role, and company details. This information helps us understand your needs and deliver services like life insurance and annuity resources, sales 
          mentorship, and coaching that are most relevant to you.
        </p>
        <p className="text-gray-700 leading-relaxed mb-2">
          <strong>Usage Data:</strong> We monitor how you interact with Orbi to enhance your experience on the platform. Usage data includes your browsing activity on our 
          site, your IP address, device details, referral sources, and the features you use most frequently. This data enables us to understand how our services are used 
          and where improvements are needed.
        </p>
        <p className="text-gray-700 leading-relaxed mb-6">
          <strong>Integration Data:</strong> If you use Orbix to connect with other systems, we collect relevant data related to these integrations. This helps us support 
          your workflows and ensure seamless data synchronization across platforms. Integration data is carefully managed to keep your systems connected and running efficiently.
        </p>

        <h2 className="text-2xl font-semibold text-[#3995EB] mb-2">2. How We Use Your Information</h2>
        <p className="text-gray-700 leading-relaxed mb-4">
          Orbi uses your information to provide personalized recommendations, training, and resources. This includes tailoring our sales mentorship and underwriting 
          advice based on your needs, optimizing our integration features, and improving the overall user experience. Your data also helps us identify trends and usage 
          patterns, which inform future updates and enhancements to our platform, ensuring you benefit from the latest advancements in life insurance and annuity services.
        </p>
        <ul className="list-disc list-inside text-gray-700 space-y-2 mb-6">
          <li><strong>Provide and Improve Services:</strong> We use your information to deliver relevant tools and content, including customized support and resources.</li>
          <li><strong>Support and Improve Functionality:</strong> Analyzing your usage helps us refine the platform, enhancing features that expedite your application process.</li>
          <li><strong>Analyze Usage Patterns:</strong> This helps us better understand our users’ needs, improving overall functionality and resource availability.</li>
        </ul>

        <h2 className="text-2xl font-semibold text-[#3995EB] mb-2">3. Sharing and Disclosure of Information</h2>
        <p className="text-gray-700 leading-relaxed mb-4">
          Orbi does not sell your personal information. We may, however, share data with trusted third-party providers who assist us in delivering our services, such as 
          customer support and technical infrastructure. Additionally, if you choose to use Orbix’s integration capabilities, information may be shared with those 
          integrated systems solely to enhance workflow efficiency. We ensure that all third-party vendors are bound by confidentiality agreements to protect your data.
        </p>
        <ul className="list-disc list-inside text-gray-700 space-y-2 mb-6">
          <li><strong>Service Providers:</strong> Trusted partners help us operate and improve Orbi, ensuring we meet high service standards and customer expectations.</li>
          <li><strong>Integrated Systems:</strong> Orbix shares only essential information with connected systems to streamline operations and improve workflow.</li>
        </ul>

        <h2 className="text-2xl font-semibold text-[#3995EB] mb-2">4. Data Security</h2>
        <p className="text-gray-700 leading-relaxed mb-6">
          Your data security is a priority at Orbi. We use advanced encryption and secure storage practices to protect your personal information from unauthorized access 
          and breaches. Our security measures are continually reviewed and updated to align with industry standards, ensuring that your data remains confidential and safe. 
          Only authorized personnel with legitimate business needs have access to your data, and we take steps to ensure compliance with data protection laws.
        </p>

        <h2 className="text-2xl font-semibold text-[#3995EB] mb-2">5. Your Privacy Choices</h2>
        <p className="text-gray-700 leading-relaxed mb-6">
          You have control over your personal data on Orbi. You can manage your information, update personal details, or change integration permissions in the account 
          settings section. Should you wish to review, modify, or delete any data, please contact us, and we’ll assist you promptly. We are committed to respecting 
          your privacy choices and ensuring you have the flexibility to control your data at any time.
        </p>

        <h2 className="text-2xl font-semibold text-[#3995EB] mb-2">6. Cookies</h2>
        <p className="text-gray-700 leading-relaxed mb-6">
          Orbi uses cookies to improve your experience on our site. Cookies help us remember your preferences, understand how you interact with our services, and make 
          our platform more user-friendly. You can control or disable cookies through your browser settings, though some features of Orbi may be affected if cookies are 
          disabled. By using our platform, you agree to the use of cookies as outlined in this policy.
        </p>

        <h2 className="text-2xl font-semibold text-[#3995EB] mb-2">7. Changes to This Privacy Policy</h2>
        <p className="text-gray-700 leading-relaxed mb-6">
          Orbi reserves the right to update this Privacy Policy to reflect changes in our practices, technology, or legal requirements. We encourage you to review 
          this policy periodically. Any updates will be posted on this page, and significant changes will be communicated to you directly. Your continued use of Orbi 
          after any changes confirms your acceptance of the updated policy.
        </p>

        {/* <h2 className="text-2xl font-semibold text-[#3995EB] mb-2">8. Contact Us</h2>
        <p className="text-gray-700 leading-relaxed">
          If you have questions or concerns regarding this Privacy Policy, please reach out to us at [Insert Contact Information]. Our team is here to provide you with 
          information and support, ensuring you have a clear understanding of how your data is handled on Orbi.
        </p> */}
      </div>
      <Footer />
    </>
  );
}

export default PrivacyPolicy;
