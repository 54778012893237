// import { Inter } from "next/font/google";
import "./home.css";
import { Orbixrebort, Svg1, Vector } from "../../assets";
import Navbar from "../../components/navbar/Navbar";
import Meetorbixs from "./MeetOrbi";
import Prompts from "./Prompts";
import Simplify from "./Simplify";
import Plans from "./Plans";
import Footer from "./Footer";
import { useLocation, useNavigate } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { routes } from "../../utils/routes";
import PlansNew from "./PlansNew";

// const inter = Inter({ subsets: ["latin"] });

export default function Home() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    if (location.search.includes("REFERRALCODE")) {
      const referralCode = location.search.split("?")[1]; // This could be dynamically set based on your application logic
      navigate(`REFERRALCODE=12356`);
    }
  }, [location]);

  return (
    <>
      <head>
        <title>Revolutionizing Insurance through Hyper-Intelligence</title>
        <meta
          name="description"
          content="Our intelligent Conversational AI solution is designed to revolutionize the way insurance companies engage."
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/orbix-logo.svg" />
      </head>
      <div className="orbix-landing-page" id="home">
        {/* <Navbar /> */}
        <div className="orbix-first-block">
          <div className="orbix-first-block-text">
            <div className="orbix-landing-main-text">
            Revolutionizing Insurance <br /> through Hyper-Intelligence 
            </div>
            <div className="color-under-line"></div>
            <div className="orbix-landing-main-text-main-detail">
              <div className="orbix-landing-main-text-1">A Journey Into The Orbix</div>
              <div className="orbix-landing-main-text-2">
                Skyrocket efficiency, improve customer retention, and empower your team!
              </div>
            </div>
            <div className="button-block">
              <button
                onClick={() => {
                  navigate(routes.signup);
                }}
                className="join-button"
              >
                Join Now
              </button>
              {/* <button className="read-butoon">Read Now</button> */}
            </div>
          </div>
          <div
            className="orbixrebort"
            data-aos="fade-right"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
          >
            <img
              src={Orbixrebort}
              alt="Picture of the author"
              width={500}
              height={500}
              quality={100}
            />
          </div>
        </div>
      </div>

      <div className="orbix-second-block">
        <div className="orbix-small-block">
          <div className="svg1">
            <img src={Svg1} />
          </div>
          <div className="orbix-second-block-text">
            <div className="orbix-second-block-text-1">Your Artificial Intelligent Bot, Orbix</div>
            <div className="orbix-second-block-text-2">
              Our intelligent Conversational AI solution is designed to revolutionize the way
              insurance companies engage.
            </div>
          </div>
        </div>
        <div
          onClick={() => {
            navigate(routes.signup);
          }}
          className="arrow-sign"
        >
          <img src={Vector} />
        </div>
      </div>

      <Meetorbixs />
      <Prompts />
      <Simplify />
      {/* <Plans /> */}
      <PlansNew />
      <Footer />
    </>
  );
}
